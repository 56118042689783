import { onMounted } from 'vue';

interface Options {
  position?: 'top' | 'bottom' | 'center';
}

let toastContainer: HTMLElement;
if (process.client) {
  toastContainer = document.createElement('ul');
  toastContainer.setAttribute('role', 'dialog');
  toastContainer.id = 'toast-container';
  toastContainer.className = 'v-toast-container';
  document.body.appendChild(toastContainer);
}
const useToast = (options?: Options) => {
  const renderToast = (msg = '', type = 'success') => {
    const toastElement = document.createElement('li');
    toastElement.innerHTML = msg;
    toastElement.setAttribute('role', 'alert');
    toastElement.className = `toast toast--${type}`;
    document.getElementById('toast-container')?.appendChild(toastElement);

    setTimeout(() => {
      toastElement.remove();
    }, 3000);
  };

  const displaySuccess = (message: string) => {
    renderToast(message);
  };

  const displayError = (message: string) => {
    renderToast(message, 'error');
  };

  // onMounted(() => {
  //   if (toastContainer) return;
  //   toastContainer = document.createElement('ul');
  //   toastContainer.id = 'toast-container';
  //   toastContainer.className = 'v-toast-container';
  //
  //   document.body.appendChild(toastContainer);
  // });

  return { displaySuccess, displayError };
};

export default useToast;
